import { validationConstants } from "constants/formValidation";
import { orderTypeConstants } from "constants/order";
import { centsToDollars, dollarsToCents } from "helpers/price";
import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { getPaymentPlanEnabled } from "pages/CreateOrder/utils/helpers";
import { ISetting } from "pages/HospitalsPage/components/PaymentAndRemit/CustomPaymentPlanItem";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { ToggleButton } from "primereact/togglebutton";
import { useEffect, useMemo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { IAccountResponse } from "types/Account/AccountResponse";
import { IPaymentPlan } from "types/Payment";

const StyledCard = styled(Card)<{ paymentPlanIsEnabledForOrderType: boolean }>`
  margin-bottom: ${sizer(4)};
  display: ${({ paymentPlanIsEnabledForOrderType }) =>
    paymentPlanIsEnabledForOrderType ? "block" : "none"};
`;

const StyledButton = styled(Button)`
  align-self: end;
`;

// TODO: this code should be refactored among with service details component refactor
// at the moment service details component triggers multiple rerenders, because of which useEffects in this component do not work properly
// and some custom settings cases are not applied

export function PaymentPlan({
  accountData,
  paymentPlan,
  isRedo,
  isDraft,
}: {
  accountData: IAccountResponse;
  paymentPlan?: IPaymentPlan;
  isRedo?: boolean;
  isDraft?: boolean;
}) {
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();
  const { paymentPlanDuration, paymentPlanMinDownPaymentPercent } =
    accountData.data;
  const customPaymentPlanSettings = accountData.data.paymentPlanCustomSettings;

  const orderTypeField = watch("orderType");
  const minDownPaymentTypeField = watch("paymentPlanMinDownPaymentType");
  const minDownPaymentPercentField = watch("paymentPlanMinDownPaymentPercent");
  const amountField = watch("amount"); // for GFE
  const totalAmountField = watch("totalAmount"); // for Patient resp and default orders
  const flatAmountField = watch("paymentPlanMinDownPaymentFlat");
  const paymentPlanDurationField = watch("paymentPlanDuration");
  const paymentPlanEnabledField = watch("paymentPlanEnabled");
  console.log("paymentPlanDurationField", paymentPlanDurationField);
  console.log("minDownPaymentTypeField", minDownPaymentTypeField);
  console.log("minDownPaymentPercentField", minDownPaymentPercentField);
  console.log("flatAmountField", flatAmountField);
  console.log("paymentPlanEnabledField", paymentPlanEnabledField);

  const paymentPlanIsEnabledForOrderType = useMemo(
    () =>
      getPaymentPlanEnabled(
        orderTypeField,
        accountData.data.paymentPlanTypeEnabled
      ),
    [orderTypeField, accountData.data.paymentPlanTypeEnabled]
  );

  const paymentPlanCustomSetting = useMemo(() => {
    if (!customPaymentPlanSettings?.length) return null;
    let amountToCompare = dollarsToCents(totalAmountField);
    if (orderTypeField === orderTypeConstants.GFE) {
      amountToCompare = dollarsToCents(amountField);
    }
    const sortedSettings = [...customPaymentPlanSettings].sort(
      (a, b) =>
        (a.min ?? Number.NEGATIVE_INFINITY) -
        (b.min ?? Number.NEGATIVE_INFINITY)
    );
    const customSetting = sortedSettings.find((setting: ISetting) => {
      const { min, max } = setting;

      if (
        min !== null &&
        min !== undefined &&
        max !== null &&
        max !== undefined
      ) {
        return amountToCompare >= min && amountToCompare <= max;
      }

      if (
        min !== null &&
        min !== undefined &&
        (max === null || max === undefined)
      ) {
        return amountToCompare >= min;
      }

      return false;
    });
    return customSetting;
  }, [amountField, totalAmountField, orderTypeField]);

  const showPercentageField = useMemo(() => {
    return minDownPaymentTypeField === "Percentage";
  }, [minDownPaymentTypeField]);

  const resetPaymentPlanFields = useCallback(() => {
    setValue("paymentPlanDuration", paymentPlanDuration);
    setValue(
      "paymentPlanMinDownPaymentPercent",
      paymentPlanMinDownPaymentPercent
    );
    setValue("paymentPlanMinDownPaymentType", "Percentage");
    setValue("paymentPlanMinDownPaymentFlat", null);
    setValue("paymentPlanEnabled", paymentPlanIsEnabledForOrderType);
  }, [
    setValue,
    paymentPlanDuration,
    paymentPlanMinDownPaymentPercent,
    paymentPlanIsEnabledForOrderType,
  ]);

  const setCustomPaymentPlanFields = useCallback(() => {
    const enabled = paymentPlanCustomSetting?.enabled;
    setValue("paymentPlanEnabled", enabled);
    if (enabled) {
      setValue(
        "paymentPlanDuration",
        paymentPlanCustomSetting.paymentPlanDuration
      );
      setValue(
        "paymentPlanMinDownPaymentType",
        paymentPlanCustomSetting.minDownPaymentType === "percent"
          ? "Percentage"
          : "Flat amount"
      );
      setValue(
        "paymentPlanMinDownPaymentFlat",
        paymentPlanCustomSetting.minDownPaymentFlat === null
          ? null
          : centsToDollars(paymentPlanCustomSetting.minDownPaymentFlat)
      );
      setValue(
        "paymentPlanMinDownPaymentPercent",
        paymentPlanCustomSetting.minDownPaymentPercent
      );
    } else {
      setValue("paymentPlanDuration", paymentPlanDuration);
      setValue("paymentPlanMinDownPaymentType", "Percentage");
      setValue(
        "paymentPlanMinDownPaymentPercent",
        paymentPlanMinDownPaymentPercent
      );
      setValue("paymentPlanMinDownPaymentFlat", null);
    }
  }, [
    setValue,
    paymentPlanCustomSetting,
    paymentPlanDuration,
    paymentPlanMinDownPaymentPercent,
  ]);

  useEffect(() => {
    if (paymentPlan || isRedo || isDraft) return;
    if (!paymentPlanCustomSetting || !paymentPlanIsEnabledForOrderType) {
      resetPaymentPlanFields();
    } else {
      setCustomPaymentPlanFields();
    }
  }, [
    paymentPlan,
    isRedo,
    isDraft,
    paymentPlanIsEnabledForOrderType,
    paymentPlanCustomSetting,
    resetPaymentPlanFields,
    setCustomPaymentPlanFields,
  ]);

  useEffect(() => {
    trigger("paymentPlanDuration");
    trigger("paymentPlanMinDownPaymentPercent");
    trigger("paymentPlanMinDownPaymentFlat");
    trigger("paymentPlanEnabled");
    trigger("paymentPlanMinDownPaymentType");
  }, [
    paymentPlanDurationField,
    minDownPaymentTypeField,
    minDownPaymentPercentField,
    flatAmountField,
    paymentPlanEnabledField,
  ]);

  const handleRefreshSettings = () => {
    if (!paymentPlanCustomSetting) {
      resetPaymentPlanFields();
    } else {
      setCustomPaymentPlanFields();
    }
  };

  const handleChangeMinDownPaymentType = (e: any) => {
    setValue("paymentPlanMinDownPaymentType", e.value);
    if (e.value === "Percentage") {
      setValue("paymentPlanMinDownPaymentFlat", null);
      if (
        paymentPlanCustomSetting &&
        paymentPlanCustomSetting.enabled &&
        paymentPlanCustomSetting.minDownPaymentType === "percent"
      ) {
        setValue(
          "paymentPlanMinDownPaymentPercent",
          paymentPlanCustomSetting.minDownPaymentPercent
        );
      } else {
        setValue(
          "paymentPlanMinDownPaymentPercent",
          paymentPlanMinDownPaymentPercent
        );
      }
    } else {
      setValue("paymentPlanMinDownPaymentPercent", null);
      if (
        paymentPlanCustomSetting &&
        paymentPlanCustomSetting.enabled &&
        paymentPlanCustomSetting.minDownPaymentType === "flat"
      ) {
        setValue(
          "paymentPlanMinDownPaymentFlat",
          centsToDollars(paymentPlanCustomSetting.minDownPaymentFlat || 0)
        );
      } else {
        setValue("paymentPlanMinDownPaymentFlat", null);
      }
    }
  };

  return (
    <StyledCard
      data-testid="paymentPlan_Card"
      title="Payment Plan"
      paymentPlanIsEnabledForOrderType={paymentPlanIsEnabledForOrderType}
    >
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-3">
            <Label
              htmlFor="paymentPlanDuration"
              data-testid="paymentPlanMonthsLabel"
            >
              Number of months
            </Label>

            <Controller
              name="paymentPlanDuration"
              control={control}
              rules={{
                required: validationConstants.PAYMENT_PLAN.DURATION_REQUIRED,
                validate: (value) =>
                  Number.isInteger(value) ||
                  validationConstants.PAYMENT_PLAN.DURATION_WHOLE_NUMBER,
                min: {
                  value: 1,
                  message: validationConstants.PAYMENT_PLAN.DURATION_MIN,
                },
                max: {
                  value: 48,
                  message: validationConstants.PAYMENT_PLAN.DURATION_MAX,
                },
              }}
              render={({ field: { onChange, value, onBlur } }) => (
                <>
                  <InputNumber
                    value={value}
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "paymentPlanDuration",
                        trigger,
                      })
                    }
                    inputId="paymentPlanDuration"
                    data-testid="months_PaymentPlan"
                    onValueChange={onChange}
                    min={1}
                    max={48}
                    placeholder="Select number of months"
                  />
                  {getFormErrorMessage("paymentPlanDuration", errors)}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12 md:col-3">
            <Label
              htmlFor="paymentPlanMinDownPaymentType"
              data-testid="paymentPlanMinDownPaymentType"
            >
              Minimum down payment type
            </Label>
            <Controller
              name="paymentPlanMinDownPaymentType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  id="paymentPlanMinDownPaymentType"
                  data-testid="minDownPaymentType_PaymentPlan"
                  value={value}
                  onChange={handleChangeMinDownPaymentType}
                  options={["Percentage", "Flat amount"]}
                  placeholder="Select minimum down payment type"
                  className="w-full"
                />
              )}
            />
          </div>
          <div
            className={`field flex-column col-12 md:col-3 ${
              showPercentageField ? "flex" : "hidden"
            }`}
          >
            <Label
              htmlFor="paymentPlanMinDownPaymentPercent"
              data-testid="paymentPlanMinDownPaymentPercent"
            >
              Minimum first payment percent
            </Label>

            <Controller
              name="paymentPlanMinDownPaymentPercent"
              control={control}
              rules={{
                required: showPercentageField
                  ? validationConstants.PAYMENT_PLAN.MIN_DOWN_PAYMENT_PERCENT
                  : false,
              }}
              render={({ field: { onChange, value, onBlur } }) => (
                <>
                  <InputNumber
                    value={value}
                    suffix="%"
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "paymentPlanMinDownPaymentPercent",
                        trigger,
                      })
                    }
                    inputId="paymentPlanMinDownPaymentPercent"
                    data-testid="minDownPaymentPercent_PaymentPlan"
                    onValueChange={onChange}
                    min={5}
                    max={100}
                  />
                  {getFormErrorMessage(
                    "paymentPlanMinDownPaymentPercent",
                    errors
                  )}
                </>
              )}
            />
          </div>
          <div
            className={`field  flex-column col-12 md:col-3 ${
              showPercentageField ? "hidden" : "flex"
            }`}
          >
            <Label
              htmlFor="paymentPlanMinDownPaymentFlat"
              data-testid="paymentPlanMinDownPaymentFlat"
            >
              Minimum first payment flat
            </Label>

            <Controller
              name="paymentPlanMinDownPaymentFlat"
              control={control}
              rules={{
                required: !showPercentageField
                  ? validationConstants.PAYMENT_PLAN.MIN_DOWN_PAYMENT_FLAT
                  : false,
              }}
              render={({ field: { onChange, value, onBlur } }) => (
                <>
                  <InputNumber
                    value={value}
                    defaultValue={value}
                    currency="USD"
                    locale="en-US"
                    mode="currency"
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "paymentPlanMinDownPaymentFlat",
                        trigger,
                      })
                    }
                    inputId="paymentPlanMinDownPaymentFlat"
                    data-testid="minDownPaymentFlat_PaymentPlan"
                    onValueChange={onChange}
                    min={10}
                    aria-autocomplete="none"
                  />
                  {getFormErrorMessage("paymentPlanMinDownPaymentFlat", errors)}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12 md:col-3">
            <Label
              htmlFor="paymentPlanEnabled"
              data-testid="paymentPlanEnabled"
            >
              Payment Plan Enabled
            </Label>
            <Controller
              name="paymentPlanEnabled"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ToggleButton
                  checked={value}
                  onChange={onChange}
                  className="w-8rem"
                />
              )}
            />
          </div>
        </div>
        {(paymentPlan || isRedo || isDraft) && (
          <StyledButton
            className="p-button-link justify-self-end w-fit"
            onClick={handleRefreshSettings}
          >
            Reset settings
          </StyledButton>
        )}
      </div>
    </StyledCard>
  );
}
