import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { paymentAvailability } from "../../../../constants/paymentPlanConstants";
import { checkForOverlappingRanges } from "../../helpers/helpers";
import { ISetting, CustomPaymentPlanItem } from "./CustomPaymentPlanItem";

const Title = styled.h3`
  margin-bottom: 10px;
  margin-left: 9px;
`;

const StyledButton = styled(Button)`
  padding: 0px;
  width: fit-content;
`;

export function PaymentPlan() {
  const { control, trigger, watch, setValue } = useFormContext<any>();
  const { handleOnBlurField } = useFormValidation();
  const paymentPlanCustomSettingsField = watch("paymentPlanCustomSettings");
  const [overlappingRanges, setOverlappingRanges] = useState(false);

  const handleAddCustomSetting = () => {
    const newSetting: ISetting = {
      min: 0,
      max: null,
      enabled: false,
      paymentPlanDuration: 0,
      minDownPaymentType: "percent",
      minDownPaymentPercent: null,
      minDownPaymentFlat: null,
    };
    setValue("paymentPlanCustomSettings", [
      ...paymentPlanCustomSettingsField,
      newSetting,
    ]);
  };

  useEffect(() => {
    let hasOverlappingRanges = false;
    if (paymentPlanCustomSettingsField?.length) {
      hasOverlappingRanges = checkForOverlappingRanges({
        settings: paymentPlanCustomSettingsField,
      });
    }
    setOverlappingRanges(hasOverlappingRanges);
  }, [paymentPlanCustomSettingsField]);

  return (
    <>
      <Title>Payment Plan</Title>
      <div className="flex flex-wrap w-100 mt-4 mb-4">
        <div className="field-checkbox col-12 md:col-3">
          <Label htmlFor="patientResponsibilityEnabled">
            Enable payment plan for Patient Responsibility
          </Label>
          <Controller
            name="patientResponsibilityEnabled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox className="ml-3" onChange={onChange} checked={value} />
            )}
          />
        </div>
        <div className="field-checkbox col-12 md:col-3">
          <Label htmlFor="edEnabled">Enable payment plan for ER</Label>
          <Controller
            name="edEnabled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox className="ml-3" onChange={onChange} checked={value} />
            )}
          />
        </div>
        <div className="field-checkbox col-12 md:col-3">
          <Label htmlFor="bariatricsEnabled">
            Enable payment plan for Bariatrics
          </Label>
          <Controller
            name="bariatricsEnabled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox className="ml-3" onChange={onChange} checked={value} />
            )}
          />
        </div>
        <div className="field-checkbox col-12 md:col-3">
          <Label htmlFor="gfeEnabled">
            Enable payment plan for Clear Estimate Pro
          </Label>
          <Controller
            name="gfeEnabled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox className="ml-3" onChange={onChange} checked={value} />
            )}
          />
        </div>
        <div className="field-checkbox col-12 md:col-3">
          <Label htmlFor="bundledEnabled">
            Enable payment plan for Clear Packages
          </Label>
          <Controller
            name="bundledEnabled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox className="ml-3" onChange={onChange} checked={value} />
            )}
          />
        </div>
      </div>
      <div className="col-12 p-0 mb-4">
        <Title>Payment Plan Settings</Title>
      </div>
      <div className="field flex flex-column col-12 md:col-3">
        <Label
          htmlFor="paymentPlanDuration"
          data-testid="paymentPlanMonthsLabel"
        >
          Default number of months
        </Label>

        <Controller
          name="paymentPlanDuration"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              id="paymentPlanDuration"
              data-testid="months_PaymentPlan"
              value={value}
              onChange={onChange}
              options={[3, 6, 9, 12, 18, 24, 36]}
              placeholder="Select number of months"
              className="w-full"
            />
          )}
        />
      </div>
      <div className="field flex flex-column col-12 md:col-3">
        <Label
          htmlFor="paymentPlanMinDownPaymentPercent"
          data-testid="paymentPlanMinDownPaymentPercent"
        >
          Default minimum first payment percent
        </Label>

        <Controller
          name="paymentPlanMinDownPaymentPercent"
          control={control}
          render={({ field: { onChange, value, onBlur } }) => (
            <InputNumber
              value={value}
              suffix="%"
              onBlur={() =>
                handleOnBlurField({
                  onBlur,
                  field: "paymentPlanMinDownPaymentPercent",
                  trigger,
                })
              }
              inputId="paymentPlanMinDownPaymentPercent"
              data-testid="minDownPaymentPercent_PaymentPlan"
              onValueChange={onChange}
              min={0}
              max={100}
            />
          )}
        />
      </div>
      <div className="field flex flex-column col-12 md:col-3 mb-4">
        <Label
          htmlFor="paymentPlanAvailability"
          data-testid="paymentPlanAvailability"
        >
          Payment plan availability
        </Label>

        <Controller
          name="paymentPlanAvailability"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              id="paymentPlanAvailability"
              data-testid="paymentPlanAvailability_PaymentPlan"
              value={value}
              onChange={onChange}
              options={Object.values(paymentAvailability)}
              className="w-full"
            />
          )}
        />
      </div>
      {paymentPlanCustomSettingsField.map(
        (setting: ISetting, index: number) => (
          <CustomPaymentPlanItem
            key={index}
            setting={setting}
            settings={paymentPlanCustomSettingsField}
            setSettings={(settings: ISetting[]) =>
              setValue("paymentPlanCustomSettings", settings)
            }
            index={index}
          />
        )
      )}
      {overlappingRanges && (
        <p className="mb-4 text-orange-500">
          Your custom settings have overlapping amount ranges. Please, remove
          the overlapping settings or change the amounts.
        </p>
      )}
      <div className="w-full col-12 flex flex-column gap-2">
        <StyledButton
          className="p-button-link gap-2"
          icon="pi pi-plus"
          onClick={handleAddCustomSetting}
        >
          <p className="small-text">Add Custom Setting</p>
        </StyledButton>
      </div>
    </>
  );
}
