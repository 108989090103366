import { validationConstants } from "constants/formValidation";
import { adjustmentTypesMapping, orderTypeConstants } from "constants/order";
import { ToastContext } from "context/ToastContext";
import { parseDate, parseTime } from "helpers/date";
import {
  parsePrice,
  centsToDollars,
  calculateDiscountPercent,
} from "helpers/price";
import { roundToX } from "helpers/round";
import useFormValidation from "hooks/useFormValidation";
import { useOrder } from "hooks/useOrder";
import usePrice from "hooks/usePrice";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { isEmpty, sumBy } from "lodash";
import { addQuantityToRow } from "pages/CreateOrder/components/ServiceDetails/helpers";
import {
  parseDateOfService,
  validateInputDatePicker,
  validateInputTime,
} from "pages/CreateOrder/utils/helpers";
import {
  handleChangeDateWithMask,
  handleChangeTimeWithMask,
} from "pages/CreateOrder/utils/mask";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useMemo, useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateOrderMutation } from "store/queries/order";
import {
  toggleDisplayAdjustments,
  toggleDisplayPatientResponsibility,
  toggleDisplayProviderPayoutCalculations,
} from "store/slices/serviceDetails";
import styled from "styled-components";
import { IDefaultServiceDetails, IOrder } from "types/Order/Order";
import { IProviderAmount } from "types/Payment";
import { SpecialtyList, SpecialtyListDict } from "types/Provider/Provider";

import PatientBenefits from "../PatientBenefits/PatientBenefits";
import PayerDetails from "../PayerDetails/PayerDetails";
import ProviderPayoutCalculations from "../ProviderPayoutCalculations/ProviderPayoutCalculations";

type ProviderRow = {
  code: string;
  cptDescription: string;
  amountInCents: number;
  specialty?: string;
  providerName?: string;
  quantity?: number;
};

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
`;

const CardHeader = styled.div`
  border-bottom: 1 solid gray;
`;

const FieldsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${sizer(4)};
`;

const StyledDataTable = styled(DataTable)`
  margin-top: ${sizer(6)};

  tr {
    background: var(--gray-50);
  }

  span.p-column-title {
    font-weight: var(--font-weight-semibold);
    font-size: var(--fontsize-contents);
    color: var(--color-black-4);
  }

  td {
    font-weight: var(--font-weight-medium);
    font-size: var(--fontsize-contents);
    color: var(--color-matte-black);
  }
`;

const Discount = styled.div`
  text-align: end;
  color: var(--color-purple);
  font-weight: var(--font-weight-semibold);
  font-size: var(--fontsize-contents);
  margin-right: var(--space-sm);
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: 720px) {
    text-align: center;
  }

  @media (min-width: 1020px) {
    text-align: end;
  }
`;

interface IServiceDetailsEdit {
  dateOfService: string;
  accountNumber: string;
  timeOfService: string;
  deductible: number;
  outOfPocketMax: number;
  flatCopay: number;
  coinsurancePercent: number;
  amountLeft: number;
}

function ServiceDetails({
  order,
  onUpdateOrder,
}: {
  order: IOrder;
  onUpdateOrder: () => void;
}) {
  const {
    control,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<IServiceDetailsEdit>();
  const [editing, setEdit] = useState(false);
  const [editingAmount, setEditAmount] = useState(false);
  const dispatch = useDispatch();
  const {
    displayAdjustments,
    displayPatientResponsibility,
    displayProviderPayoutCalculations,
  } = useSelector((state: any) => state.serviceDetailsSlice);
  const { current: toastElement } = useContext(ToastContext);
  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();

  const { getClearPriceByOrderType, insuranceAmounts, getInsuranceAmounts } =
    usePrice();
  const { isDefaultOrderType } = useOrder(order);

  const [updateOrder] = useUpdateOrderMutation();
  const firstColumnTemplate = (row: ProviderRow) => {
    return <span className="font-bold">{row.providerName}</span>;
  };

  const firstColumnBold = (text: string) => {
    return <span className="font-bold">{text}</span>;
  };

  useEffect(() => {
    setValue("dateOfService", parseDate(order.dateOfService));
    setValue("accountNumber", order.accountNumber);
    setValue("timeOfService", parseTime(order.dateOfService));
    setValue(
      "deductible",
      centsToDollars(order?.gfeQuote?.remainingDeductibleInCents || 0)
    );
    setValue(
      "outOfPocketMax",
      centsToDollars(order?.gfeQuote?.outOfPocketMaxInCents || 0)
    );
    setValue(
      "flatCopay",
      centsToDollars(order?.gfeQuote?.flatCopayInCents || 0)
    );
    setValue("coinsurancePercent", order?.gfeQuote?.coinsurancePercent || 0);
  }, [order, setValue]);

  const columns = useMemo(() => {
    if (order.orderType === orderTypeConstants.PATIENT_RESPONSIBILITY) {
      return [
        { field: "code", header: "CPT Code" },
        { field: "cptDescription", header: "CPT Description" },
      ];
    }

    return [
      {
        field: "code",
        header: "CPT Code/Name",
        body: (row: ProviderRow) => firstColumnBold(row.code),
      },
      {
        field: "specialty",
        header: "Specialty",
        body: (row: ProviderRow) =>
          SpecialtyListDict[row.specialty as SpecialtyList],
      },
      {
        header: "Amount",
        field: "amountInCents",
        body: (row: ProviderRow) => {
          const quantity = row.quantity || 1;
          return parsePrice(centsToDollars(row.amountInCents * quantity));
        },
      },
      { field: "providerName", header: "Provider" },
      { field: "quantity", header: "Quantity" },
    ];
  }, [order]);

  const columnsAdjustmentAndPatientResponsibility = useMemo(() => {
    return [
      {
        field: "providerName",
        header: "Provider",
        body: (row: ProviderRow) => firstColumnTemplate(row),
      },
      {
        field: "code",
        header: "CPT Code/Name",
      },
      {
        field: "specialty",
        header: "Description",
      },
      {
        field: "amountInCents",
        header: "Amount",
        body: (row: ProviderRow) =>
          parsePrice(centsToDollars(row.amountInCents)),
      },
    ];
  }, [order]);

  const parseDataToPatientResponsibility = useMemo(() => {
    return (
      order.gfeQuote?.pricingBreakdown.procedureBreakdown?.map(
        (aPatientResponsibility) => {
          const { code, owedByPatient, providerName } = aPatientResponsibility;
          const { coinsurance, deductible, total } = owedByPatient;

          let description = "";

          if (coinsurance > 0 && deductible > 0) {
            description = "Deductible + Coinsurance";
          } else if (coinsurance > 0) {
            description = "Coinsurance";
          } else if (deductible > 0) {
            description = "Deductible";
          }
          return {
            code,
            amountInCents: total,
            specialty: description,
            providerName,
          };
        }
      ) || []
    );
  }, [order.gfeQuote]);

  const parseDataToAdjustment = useMemo(() => {
    return (
      order.gfeQuote?.pricingBreakdown.adjustments?.map(
        (aPatientResponsibility) => {
          const { procedure, discountType, discountedPrice } =
            aPatientResponsibility;
          const { code, providerName } = procedure;

          return {
            code,
            amountInCents: discountedPrice,
            specialty: adjustmentTypesMapping[discountType],
            providerName,
          };
        }
      ) || []
    );
  }, [order.gfeQuote]);

  const paymentBreakdownDetails = useMemo(() => {
    const list = order?.paymentFullBreakdown?.bundledProviderAmounts;

    const sortedList = list?.length
      ? [...list].sort((a, b) => b.providerPriority - a.providerPriority)
      : [];

    // Transform IProviderAmount to ProviderAmountRow
    return sortedList.map((item: IProviderAmount) => ({
      ...item,
      patientPortion: item.patientPortion || 0, // Provide a default value or calculate
      remainingToPayProvider: item.remainingToPayProvider || 0, // Provide a default value or calculate
    }));
  }, [order]);

  const clearFee = useMemo(() => {
    return order?.paymentFullBreakdown?.clearFeeAmountInCents;
  }, [order]);

  const payments = order.paymentPlanBreakdown
    ? order.paymentPlanBreakdown.payments
    : undefined;

  useEffect(() => {
    if (!isEmpty(order.gfeQuote)) {
      getInsuranceAmounts(order.gfeQuote.pricingBreakdown);
    }
  }, [order.gfeQuote]);

  const paymentInfo = useMemo(() => {
    const { total, clearPrice } = getClearPriceByOrderType({ order });

    const subtotalValue = sumBy(
      order.serviceDetails.procedures as any,
      "amountInCents"
    );

    const subTotal = isDefaultOrderType ? subtotalValue : total;
    const totalWithDiscount = clearPrice;

    const rawTotal = parsePrice(centsToDollars(subTotal));
    const rawTotalWithDiscount = parsePrice(centsToDollars(totalWithDiscount));
    let providerFinalAmount;
    if (order.orderType === orderTypeConstants.PATIENT_RESPONSIBILITY) {
      const providerAmountInCents =
        order?.paymentFullBreakdown?.patientRespFacilityAmountInCents;
      providerFinalAmount =
        providerAmountInCents &&
        parsePrice(centsToDollars(providerAmountInCents));
    }

    const discountPercentForPatientResponsibility = roundToX(
      calculateDiscountPercent(total, totalWithDiscount),
      2
    );

    return {
      rawTotalWithDiscount,
      rawTotal,
      discountPercentForPatientResponsibility,
      providerFinalAmount,
    };
  }, [order, order.serviceDetails, order.serviceDetails.procedures]);

  const resetForm = () => {
    setValue("dateOfService", parseDate(order.dateOfService));
    setValue("accountNumber", order.accountNumber);
    setValue("timeOfService", parseTime(order.dateOfService));
  };

  const mapServiceDetailsUpdateRequest = (values: IServiceDetailsEdit) => ({
    dateOfService: parseDateOfService({
      dateOfService: values.dateOfService,
      timeOfService: values.timeOfService,
    }),
    accountNumber: values.accountNumber,
  });

  const submit = () => {
    const values = mapServiceDetailsUpdateRequest(getValues());

    updateOrder({ id: order.id, ...values })
      .unwrap()
      .then(() => {
        onUpdateOrder();
        toastElement?.show({
          summary: "Success!",
          severity: "success",
          detail: "Your order has been updated.",
        });
        setEdit(false);
      })
      .catch(() => {
        toastElement?.show({
          severity: "error",
          detail: "Try again later.",
          summary: "Something went wrong.",
        });
      });
  };

  const actionFooter = () => {
    return (
      <>
        <Button label="Save" icon="pi pi-check" onClick={submit} />
        <Button
          label="Cancel"
          onClick={() => {
            resetForm();
            setEdit(false);
            setEditAmount(false);
          }}
          icon="pi pi-times"
          style={{ marginLeft: "0.5em" }}
        />
      </>
    );
  };

  const actionHeader = () => {
    return (
      <div className="flex flex-column col-12 align-items-end">
        <div>
          <Button
            label="Edit"
            icon="pi pi-check"
            onClick={() => setEdit(true)}
          />
        </div>
      </div>
    );
  };

  const orderTypeRules = useMemo(() => {
    const isGFEorPatientResponsibility =
      order.orderType === orderTypeConstants.PATIENT_RESPONSIBILITY ||
      order.orderType === orderTypeConstants.GFE;
    const isPatientResponsibility =
      order.orderType === orderTypeConstants.PATIENT_RESPONSIBILITY;
    const isGFE = order.orderType === orderTypeConstants.GFE;

    return { isGFEorPatientResponsibility, isPatientResponsibility, isGFE };
  }, [order.orderType]);

  return (
    <>
      {orderTypeRules.isGFE && (
        <>
          <PayerDetails order={order} />
          <PatientBenefits order={order} />
        </>
      )}
      <StyledCard title="Service Details" header={!editing && actionHeader()}>
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-4">
            <Label htmlFor="dateOfService">Date of service</Label>

            <Controller
              data-testid="dateOfService"
              name="dateOfService"
              control={control}
              rules={{
                validate: (date) => validateInputDatePicker(date, "future"),
                required: validationConstants.VISIT_INFO_FORM.DATE_OF_SERVICE,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    disabled={!editing}
                    id="dateOfService"
                    value={value || ""}
                    placeholder="99/99/9999"
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "dateOfService",
                        trigger,
                      })
                    }
                    onChange={(e) => handleChangeDateWithMask(e, onChange)}
                  />

                  {getFormErrorMessage("dateOfService", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-4">
            <Label htmlFor="timeOfService">Time of service</Label>

            <Controller
              control={control}
              name="timeOfService"
              data-testid="timeOfService"
              rules={{
                validate: (time) => validateInputTime(time),
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    disabled={!editing}
                    id="timeOfService"
                    value={value}
                    placeholder="HH:MM PM"
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "timeOfService",
                        trigger,
                      })
                    }
                    onChange={(e) => handleChangeTimeWithMask(e, onChange)}
                  />

                  {getFormErrorMessage("timeOfService", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-4">
            <Label htmlFor="accountId">Account/ Visit ID</Label>

            <Controller
              name="accountNumber"
              control={control}
              rules={{
                required: validationConstants.VISIT_INFO_FORM.VISIT_ID,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    disabled={!editing}
                    data-testid="accountNumber"
                    id="accountNumber"
                    placeholder="Account ID"
                    autoComplete="off"
                    value={value}
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "accountNumber",
                        trigger,
                      })
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />

                  {getFormErrorMessage("accountNumber", errors)}
                </>
              )}
            />
          </div>

          {!orderTypeRules.isGFEorPatientResponsibility && (
            <div className="field flex flex-column col-12 md:col-4">
              <Label htmlFor="estimatedVisitCost">
                Facility Patient Estimate
              </Label>

              <InputText
                disabled
                id="estimatedVisitCost"
                aria-labelledby="estimatedVisitCost"
                value={parsePrice(
                  centsToDollars(
                    (order.serviceDetails as IDefaultServiceDetails)
                      .estimatedVisitCost
                  )
                )}
              />
            </div>
          )}
        </div>

        {editing && actionFooter()}

        <StyledDataTable
          rowGroupMode="rowspan"
          style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
          groupRowsBy="code"
          value={
            orderTypeRules.isPatientResponsibility
              ? order.serviceDetails.procedures
              : addQuantityToRow(order.serviceDetails.procedures)
          }
        >
          {columns.map((col) => (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              body={col?.body}
            />
          ))}
        </StyledDataTable>
        {orderTypeRules.isGFE && (
          <>
            <div>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: sizer(5),
                }}
                onClick={() => {
                  dispatch(toggleDisplayAdjustments());
                }}
              >
                <span
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: 700,
                  }}
                >
                  Adjustments
                </span>
                {displayAdjustments ? (
                  <i
                    className="pi pi-angle-up
"
                    style={{ fontSize: "1.5rem" }}
                  />
                ) : (
                  <i
                    className="pi pi-angle-down
"
                    style={{ fontSize: "1.5rem" }}
                  />
                )}
              </CardHeader>
              {displayAdjustments && (
                <StyledDataTable
                  rowGroupMode="rowspan"
                  style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
                  emptyMessage="No adjustments"
                  value={parseDataToAdjustment}
                >
                  {columnsAdjustmentAndPatientResponsibility.map((col) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      body={col?.body}
                      header={col?.header}
                    />
                  ))}
                </StyledDataTable>
              )}
            </div>
            <div>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: sizer(5),
                }}
                onClick={() => {
                  dispatch(toggleDisplayPatientResponsibility());
                }}
              >
                <span
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: 700,
                  }}
                >
                  Patient Responsibility
                </span>
                {displayPatientResponsibility ? (
                  <i
                    className="pi pi-angle-up
"
                    style={{ fontSize: "1.5rem" }}
                  />
                ) : (
                  <i
                    className="pi pi-angle-down
"
                    style={{ fontSize: "1.5rem" }}
                  />
                )}
              </CardHeader>
              {displayPatientResponsibility && (
                <StyledDataTable
                  rowGroupMode="rowspan"
                  style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
                  emptyMessage="No Patient Responsibility"
                  value={parseDataToPatientResponsibility}
                >
                  {columnsAdjustmentAndPatientResponsibility.map((col) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      body={col?.body}
                      header={col?.header}
                    />
                  ))}
                </StyledDataTable>
              )}
            </div>
            <div>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: sizer(5),
                }}
                onClick={() => {
                  dispatch(toggleDisplayProviderPayoutCalculations());
                }}
              >
                <span
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: 700,
                  }}
                >
                  Provider Payout Calculations
                </span>
                <i
                  className={
                    displayProviderPayoutCalculations
                      ? "pi pi-angle-up"
                      : "pi pi-angle-down"
                  }
                  style={{ fontSize: "1.5rem" }}
                />
              </CardHeader>
              {displayProviderPayoutCalculations && (
                <ProviderPayoutCalculations
                  providerAmounts={paymentBreakdownDetails}
                  clearFee={clearFee}
                  payments={payments}
                />
              )}
            </div>
          </>
        )}
        <div>
          {orderTypeRules.isGFE && (
            <>
              <FieldsContainer>
                <div className="flex align-items-center">
                  <label
                    htmlFor="totalInsuranceAllowable"
                    className="mr-2 flex align-items-center"
                  >
                    Total Insurance Allowable
                  </label>

                  <InputText
                    disabled
                    id="totalInsuranceAllowable"
                    aria-labelledby="totalInsuranceAllowable"
                    value={parsePrice(
                      centsToDollars(
                        insuranceAmounts?.totalAllowedInsuranceAmount
                      )
                    )}
                  />
                </div>
              </FieldsContainer>
              <FieldsContainer>
                <div className="flex align-items-center">
                  <label
                    htmlFor="totalInsurancePortion"
                    className="mr-2 flex align-items-center"
                  >
                    Total Insurance Portion
                  </label>

                  <InputText
                    disabled
                    id="totalInsurancePortion"
                    aria-labelledby="totalInsurancePortion"
                    value={parsePrice(
                      centsToDollars(insuranceAmounts?.totalInsurancePayInCents)
                    )}
                  />
                </div>
              </FieldsContainer>
            </>
          )}
          <FieldsContainer>
            <div className="flex align-items-center">
              <label
                htmlFor="subtotal"
                className="mr-2 flex align-items-center"
              >
                {orderTypeRules.isGFEorPatientResponsibility
                  ? `Total estimated patient cost`
                  : "Subtotal Cost"}
              </label>

              <InputText
                disabled
                id="subtotal"
                aria-labelledby="subtotal"
                value={paymentInfo.rawTotal}
              />
            </div>
          </FieldsContainer>

          <FieldsContainer>
            <div className="flex align-items-center">
              <label
                htmlFor="totalPrice"
                className="mr-2 flex align-items-center"
              >
                {orderTypeRules.isGFEorPatientResponsibility
                  ? "Clear price offered to patient"
                  : "Clear Price for Patient"}
              </label>

              <InputText
                disabled
                id="totalPrice"
                aria-labelledby="totalPrice"
                value={paymentInfo.rawTotalWithDiscount}
              />
            </div>
          </FieldsContainer>

          {orderTypeRules.isGFEorPatientResponsibility && (
            <Discount className="mt-3 mr-0">
              {paymentInfo.discountPercentForPatientResponsibility
                ? `${paymentInfo.discountPercentForPatientResponsibility}%
              discount`
                : ""}
            </Discount>
          )}
          {paymentInfo.providerFinalAmount && (
            <FieldsContainer>
              <div className="flex align-items-center">
                <label
                  htmlFor="finalPayment"
                  className="mr-2 flex align-items-center"
                >
                  Provider final payment
                </label>

                <InputText
                  disabled
                  id="finalPayment"
                  aria-labelledby="finalPayment"
                  value={paymentInfo.providerFinalAmount}
                />
              </div>
            </FieldsContainer>
          )}
        </div>
      </StyledCard>
    </>
  );
}

export default ServiceDetails;
