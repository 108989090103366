import { parseDate } from "helpers/date";
import { parsePrice, centsToDollars } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Paginator, PaginatorPageState } from "primereact/paginator";
import { Row } from "primereact/row";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

type ProviderAmountRow = {
  providerName: string;
  cptCode: string;
  providerPriority: number;
  adjustedAllowablePrice: number;
  clearDiscountedPrice: number;
  expectedTransferAmount: number;
  clearFeeAmountInCents: number;
  patientPortion: number;
  remainingToPayProvider: number;
};

const StyledDataTable = styled(DataTable)`
  margin-top: ${sizer(6)};

  tr {
    background: var(--gray-50);
  }

  span.p-column-title {
    font-weight: var(--font-weight-semibold);
    font-size: var(--fontsize-contents);
    color: var(--color-black-4);
  }
  .p-rowgroup-header {
    display: none;
  }

  td {
    font-weight: var(--font-weight-medium);
    font-size: var(--fontsize-contents);
    color: var(--color-matte-black);
  }
`;

function ProviderPayoutCalculations({
  providerAmounts,
  clearFee,
  payments = [],
}: {
  providerAmounts?: ProviderAmountRow[];
  clearFee?: number | null;
  payments?: any[];
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [rows] = useState(1); // Show one payment at a time
  const { watch } = useFormContext();
  const remainingInstallmentsField = watch("paymentPlanDuration");

  useEffect(() => {
    // Reset currentPage to 0 whenever Installments count changes
    setCurrentPage(0);
  }, [remainingInstallmentsField]);

  const onPageChange = (event: PaginatorPageState) => {
    setCurrentPage(event.first);
  };

  const firstColumnTemplate = (row: ProviderAmountRow) => {
    return <span className="font-bold">{row.providerName}</span>;
  };

  const fullPaymentColumns = useMemo(() => {
    return [
      {
        field: "providerName",
        header: "Provider",
        body: (row: ProviderAmountRow) => firstColumnTemplate(row),
      },
      { field: "cptCode", header: "CPT Code" },
      { field: "providerPriority", header: "Payment Priority" },
      {
        field: "adjustedAllowablePrice",
        header: "Total Allowable",
        body: (row: ProviderAmountRow) => {
          return parsePrice(centsToDollars(row.adjustedAllowablePrice));
        },
      },
      {
        field: "patientPortion",
        header: "Patient Portion",
        body: (row: ProviderAmountRow) => {
          return parsePrice(centsToDollars(row.patientPortion));
        },
      },
      {
        field: "clearDiscountedPrice",
        header: "Discounted Price",
        body: (row: ProviderAmountRow) => {
          return parsePrice(centsToDollars(row.clearDiscountedPrice));
        },
      },
      {
        field: "expectedTransferAmount",
        header: "Actual Payment",
        body: (row: ProviderAmountRow) => {
          return parsePrice(centsToDollars(row.expectedTransferAmount));
        },
      },
    ];
  }, []);

  // Format clearfee with how many decimal we want to show, this is for development purposes
  const formatClearFee = (amount: number) => {
    return (amount / 100).toFixed(2);
  };

  const installmentColumns = useMemo(() => {
    return [
      {
        field: "providerName",
        header: "Provider",
        body: (row: ProviderAmountRow) => firstColumnTemplate(row),
      },
      {
        field: "cptCode",
        header: "CPT Code",
        body: (row: ProviderAmountRow) => {
          return row.cptCode || "N/A";
        },
      },
      { field: "providerPriority", header: "Payment Priority" },
      {
        field: "expectedTransferAmount",
        header: "Actual Payment",
        body: (row: ProviderAmountRow) => {
          return parsePrice(centsToDollars(row.expectedTransferAmount));
        },
      },
    ];
  }, []);

  const footerGroup = (clearFeeValue: number | null, columnCount: number) => (
    <ColumnGroup>
      <Row>
        <Column
          footer="Clear Fee"
          colSpan={columnCount - 1}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={
            clearFeeValue !== null ? formatClearFee(clearFeeValue) : "N/A"
          }
        />
      </Row>
    </ColumnGroup>
  );

  // Sort providerAmounts by providerPriority in ascending order
  const sortedProviderAmounts = useMemo(() => {
    return (providerAmounts || [])
      .slice()
      .sort(
        (a: ProviderAmountRow, b: ProviderAmountRow) =>
          a.providerPriority - b.providerPriority
      );
  }, [providerAmounts]);

  // Sort payments by providerPriority in ascending order
  const sortedPayments = useMemo(() => {
    return payments.map((payment) => ({
      ...payment,
      providerBreakdown: payment.providerBreakdown
        .slice()
        .sort(
          (a: ProviderAmountRow, b: ProviderAmountRow) =>
            a.providerPriority - b.providerPriority
        ),
    }));
  }, [payments]);

  return (
    <>
      <h3 style={{ marginTop: sizer(4) }}>Payment Full</h3>
      <StyledDataTable
        rowGroupMode="subheader"
        style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
        groupRowsBy="providerName"
        value={sortedProviderAmounts}
        emptyMessage="No Provider Payout Calculations"
        footerColumnGroup={footerGroup(
          clearFee ?? null,
          fullPaymentColumns.length
        )}
      >
        {fullPaymentColumns.map((col) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={col?.body}
          />
        ))}
      </StyledDataTable>

      {sortedPayments?.length > 0 && (
        <div style={{ marginBottom: sizer(4) }}>
          <h3 style={{ marginBottom: sizer(3), marginTop: sizer(3) }}>
            {currentPage === 0
              ? "Initial Deposit"
              : `Installment ${currentPage}`}{" "}
            -
            {currentPage === 0 ||
            sortedPayments[currentPage].installmentInDb?.paymentId
              ? "Paid Date"
              : "Expected Payment Date"}
            :{parseDate(sortedPayments[currentPage].dateDue)} - Amount:
            {parsePrice(
              centsToDollars(sortedPayments[currentPage].amountInCents)
            )}
          </h3>
          <StyledDataTable
            rowGroupMode="subheader"
            style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
            groupRowsBy="providerName"
            value={sortedPayments[currentPage].providerBreakdown}
            emptyMessage="No Provider Breakdown"
            footerColumnGroup={footerGroup(
              sortedPayments[currentPage].providerBreakdown.find(
                (provider: ProviderAmountRow) =>
                  provider.clearFeeAmountInCents > 0
              )?.clearFeeAmountInCents || 0,
              installmentColumns.length
            )}
          >
            {installmentColumns.map((col) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={col?.body}
              />
            ))}
          </StyledDataTable>
          <Paginator
            first={currentPage}
            rows={rows}
            totalRecords={payments.length}
            onPageChange={onPageChange}
          />
          <div style={{ borderBottom: "1px solid #ccc", marginTop: "8px" }} />
        </div>
      )}
    </>
  );
}

export default ProviderPayoutCalculations;
