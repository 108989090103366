import { orderTypeConstants } from "constants/order";
import { calculatePercentageOff, dollarsToCents } from "helpers/price";
import { isEmpty } from "lodash";
import { useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLazyGetEstimateCalculationQuery } from "store/queries/pricing";
import { setLoadingPriceBreakdown } from "store/slices/serviceDetails";
import { PricingBreakdown } from "types/CreateOrder";
import {
  IDefaultServiceDetails,
  IOrder,
  IPatientResponsibilityServiceDetails,
} from "types/Order/Order";

export default function usePrice() {
  const dispatch = useDispatch();
  const [priceBreakdown, setPriceBreakdown] = useState<
    PricingBreakdown | undefined
  >(undefined);
  const [trigger] = useLazyGetEstimateCalculationQuery();
  const PHYSICIAN_FEE_PERCENTAGE = 0.18;
  const getClearPriceByOrderType = ({ order }: { order: IOrder }) => {
    if (!order) {
      return {
        clearPrice: 0,
        total: 0,
        totalSaving: 0,
        facilityFee: 0,
        physicianFee: 0,
        percentageOff: 0,
      };
    }

    switch (order.orderType) {
      case orderTypeConstants.PATIENT_RESPONSIBILITY: {
        const clearPrice = (
          order.serviceDetails as IPatientResponsibilityServiceDetails
        )?.patientResponsibilityEstimateAmountInCents;

        const total = order?.amountInCents;
        const facilityFee = order?.amountInCents;

        const totalSaving = total - clearPrice;

        const percentageOff = calculatePercentageOff(total, clearPrice);

        return { clearPrice, total, totalSaving, percentageOff, facilityFee };
      }
      case orderTypeConstants.GFE: {
        const total = (order.serviceDetails as any)
          ?.patientResponsibilityEstimateAmountInCents;

        const clearPrice = order?.amountInCents;
        const facilityFee = total;

        const totalSaving = facilityFee - clearPrice;

        const percentageOff = calculatePercentageOff(total, clearPrice);

        return { clearPrice, total, totalSaving, percentageOff, facilityFee };
      }
      case orderTypeConstants.ED: {
        const clearPrice = order?.amountInCents;

        const facilityFee = (order.serviceDetails as IDefaultServiceDetails)
          ?.estimatedVisitCost;

        const physicianFee = facilityFee * PHYSICIAN_FEE_PERCENTAGE;
        const totalSaving = facilityFee - clearPrice;
        const total = facilityFee;
        const percentageOff = calculatePercentageOff(facilityFee, clearPrice);

        return {
          clearPrice,
          total,
          totalSaving,
          physicianFee,
          facilityFee,
          percentageOff,
        };
      }

      default: {
        const clearPrice = order?.amountInCents;

        const facilityFee = (order.serviceDetails as IDefaultServiceDetails)
          ?.estimatedVisitCost;

        const physicianFee = facilityFee * PHYSICIAN_FEE_PERCENTAGE;
        const totalSaving = facilityFee + physicianFee - clearPrice;
        const total = facilityFee + physicianFee;

        const percentageOff = calculatePercentageOff(
          facilityFee + physicianFee,
          clearPrice
        );

        return {
          clearPrice,
          total,
          totalSaving,
          physicianFee,
          facilityFee,
          percentageOff,
        };
      }
    }
  };
  const getGFEPrices = async ({
    serviceDetails,
    remainingDeductibleInDollar,
    outOfPocketMaxInDollar,
    flatCopayInDollar,
    coinsurancePercent,
  }: any) => {
    if (
      isEmpty(serviceDetails) ||
      remainingDeductibleInDollar === null ||
      outOfPocketMaxInDollar === null ||
      coinsurancePercent === null
    ) {
      setPriceBreakdown(undefined);
      return;
    }
    dispatch(setLoadingPriceBreakdown(true));
    const response = await trigger({
      serviceDetails: { ...serviceDetails },
      remainingDeductibleInCents: dollarsToCents(
        remainingDeductibleInDollar || 0
      ),
      outOfPocketMaxInCents: dollarsToCents(outOfPocketMaxInDollar || 0),
      flatCopayInCents: dollarsToCents(flatCopayInDollar || 0),
      coinsurancePercent: coinsurancePercent || 0,
    });
    if (response?.data?.data) {
      setPriceBreakdown(response.data.data);
    }
  };
  const insuranceAmounts = useMemo(() => {
    const totalDeductibleInCents =
      priceBreakdown?.procedureBreakdown.reduce(
        (sum, procedure) => sum + procedure.owedByPatient.deductible,
        0
      ) || 0;

    const totalCoinsuranceInCents =
      priceBreakdown?.procedureBreakdown.reduce(
        (sum, procedure) => sum + procedure.owedByPatient.coinsurance,
        0
      ) || 0;

    const totalOrderPayInCents =
      priceBreakdown?.procedureBreakdown.reduce(
        (sum, procedure) => sum + (procedure.amountInCents || 0),
        0
      ) || 0;

    const totalFlatCopayInCents =
      priceBreakdown?.procedureBreakdown.reduce(
        (sum, procedure) => sum + (procedure.owedByPatient?.copay || 0),
        0
      ) || 0;

    const totalPatientPays = priceBreakdown?.totalInCents || 0;

    const totalInsurancePayInCents = totalOrderPayInCents - totalPatientPays;

    const totalAllowedInsuranceAmount =
      totalDeductibleInCents +
      totalCoinsuranceInCents +
      totalFlatCopayInCents +
      totalInsurancePayInCents;

    return {
      totalDeductibleInCents,
      totalCoinsuranceInCents,
      totalInsurancePayInCents,
      totalOrderPayInCents,
      totalPatientPays,
      totalAllowedInsuranceAmount,
      totalFlatCopayInCents,
    };
  }, [priceBreakdown]);

  const getInsuranceAmounts = useCallback(
    (prices: PricingBreakdown | undefined) => {
      setPriceBreakdown(prices);
    },
    [setPriceBreakdown]
  );

  const clearPriceBreakdown = useCallback(() => {
    setPriceBreakdown(undefined);
  }, [setPriceBreakdown]);

  return {
    getClearPriceByOrderType,
    getGFEPrices,
    priceBreakdown,
    insuranceAmounts,
    getInsuranceAmounts,
    clearPriceBreakdown,
  };
}
