import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import { Button } from "primereact/button";
import { useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUpdateAccountMutation } from "store/queries/account";

import { validaTeCustomPaymentPlanSettings } from "../helpers/helpers";
import { mapFormToRequestBody } from "../helpers/mapFormToRequestBody";

export function SubmitEdit({ id }: { id: string }) {
  const [useUpdateAccount] = useUpdateAccountMutation();
  const { accountRefetch } = useUserWithAccount();
  const { current: toastElement } = useContext(ToastContext);
  const {
    getValues,
    formState: { isValid },
    watch,
  } = useFormContext();
  const paymentPlanCustomSettingsField = watch("paymentPlanCustomSettings");
  const navigate = useNavigate();

  const updateHospital = async () => {
    const requestBody = await mapFormToRequestBody(getValues());
    try {
      useUpdateAccount({ ...requestBody, id })
        .unwrap()
        .then(() => {
          toastElement?.show({
            severity: "success",
            summary: "Account updated",
            detail: "Account updated successfully",
          });
          navigate("/hospitals");
          accountRefetch();
        })
        .catch(() =>
          toastElement?.show({
            severity: "error",
            summary: "Error",
            detail: "An error has occurred attempting to update the account",
          })
        );
    } catch (error) {
      console.error(error);
    }
  };

  const submitButtonDIsabled = useMemo(() => {
    if (!isValid) return true;
    if (paymentPlanCustomSettingsField?.length) {
      const isCustomSettingsValid = validaTeCustomPaymentPlanSettings({
        settings: paymentPlanCustomSettingsField,
      });
      if (!isCustomSettingsValid) return true;
    }
    return false;
  }, [isValid, paymentPlanCustomSettingsField]);

  return (
    <ItemBar>
      <div
        data-testid="submit__Hospital"
        className="flex flex-1 justify-content-end"
      >
        <Button
          label="Update Hospital"
          onClick={updateHospital}
          disabled={submitButtonDIsabled}
        />
      </div>
    </ItemBar>
  );
}
