import { capitalizeFirstLetter } from "helpers/text";
import { buildURLforOrders } from "pages/Orders/helpers/helpers";
import {
  IExtendedOrder,
  IOrderCreateRequest,
  IOrderCancelRequest,
  IOrdersRequest,
} from "types/Order/Order";
import {
  IOrdersResponse,
  ISingleOrderResponse,
  ISingleOrderResumeResponse,
} from "types/Order/OrderResponse";
import { ICreatePaymentRequest } from "types/Payment";

import { publicApi, api } from ".";

const orderQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<IOrdersResponse, IOrdersRequest>({
      query: ({
        accountId,
        page,
        pageSize,
        status,
        orderType,
        patientName,
        dateOfService,
        sortOrder,
        sortField,
      }) =>
        buildURLforOrders({
          accountId,
          page,
          pageSize,
          status,
          orderType,
          patientName,
          dateOfService,
          sortOrder,
          sortField,
        }),
    }),
    getOrder: builder.query<ISingleOrderResponse, string | undefined>({
      query: (orderId) => `/orders/${orderId}`,
    }),
    createOrders: builder.mutation<ISingleOrderResponse, IOrderCreateRequest>({
      query: (newOrder) => ({
        url: "orders",
        method: "POST",
        body: newOrder,
      }),
    }),
    cancelSingleOrder: builder.mutation<
      ISingleOrderResponse,
      IOrderCancelRequest
    >({
      query: ({ orderId, note }: { orderId: string; note?: string }) => ({
        url: `orders/${orderId}/cancel`,
        method: "PATCH",
        body: {
          note,
        },
      }),
    }),
    updateOrder: builder.mutation<IOrdersResponse, Partial<IExtendedOrder>>({
      query: (orderToUpdate) => ({
        method: "PATCH",
        url: `/orders/${orderToUpdate.id}`,
        body: {
          amountInCents: orderToUpdate.amountInCents,
          orderType: orderToUpdate.orderType,
          integrationErrors: orderToUpdate.integrationErrors,
          orderAttemptNumber: 0,
          externalId: orderToUpdate.externalId,
          serviceDetails: orderToUpdate.serviceDetails,
          accountNumber: orderToUpdate.accountNumber,
          dateOfService: orderToUpdate.dateOfService,
          status: orderToUpdate?.status,
          gfeQuoteId: orderToUpdate.gfeQuoteId,
          patientId: orderToUpdate.patientId,
          patient: {
            firstName: orderToUpdate.patient?.firstName,
            lastName: orderToUpdate.patient?.lastName,
            dateOfBirth: orderToUpdate.patient?.dateOfBirth,
            preferredContactMethod:
              orderToUpdate.patient?.preferredContactMethod,
            phoneNumber: orderToUpdate.patient?.phoneNumber,
            updatedAt: new Date(),
            patientBenefits: {
              isSubscriberPatient:
                orderToUpdate.patient?.patientBenefits.isSubscriberPatient,
              memberID: orderToUpdate.patient?.patientBenefits.memberID,
            },
          },
          paymentPlanDuration: orderToUpdate?.paymentPlanDuration,
          paymentPlanMinDownPaymentPercent:
            orderToUpdate?.paymentPlanMinDownPaymentPercent,
          paymentPlan: orderToUpdate?.paymentPlan,
          outreachStopped: orderToUpdate?.outreachStopped,
        },
      }),
    }),
    redoOrder: builder.mutation<ISingleOrderResponse, IOrderCreateRequest>({
      query: (orderToUpdate) => ({
        method: "PATCH",
        url: `/orders/redo/${orderToUpdate.orderId}`,
        body: orderToUpdate,
      }),
    }),
    saveDraft: builder.mutation<IOrdersResponse, Partial<IExtendedOrder>>({
      query: (orderToUpdate) => ({
        method: "PATCH",
        url: `/orders/draft/${orderToUpdate.id}`,
        body: {
          amountInCents: orderToUpdate.amountInCents,
          orderType: orderToUpdate.orderType,
          integrationErrors: orderToUpdate.integrationErrors,
          orderAttemptNumber: 0,
          externalId: orderToUpdate.externalId,
          serviceDetails: orderToUpdate.serviceDetails,
          accountNumber: orderToUpdate.accountNumber,
          dateOfService: orderToUpdate.dateOfService,
          status: orderToUpdate?.status,
          gfeQuoteId: orderToUpdate.gfeQuoteId,
          patientId: orderToUpdate.patientId,
          patient: {
            firstName: orderToUpdate.patient?.firstName,
            lastName: orderToUpdate.patient?.lastName,
            dateOfBirth: orderToUpdate.patient?.dateOfBirth,
            preferredContactMethod:
              orderToUpdate.patient?.preferredContactMethod,
            phoneNumber: orderToUpdate.patient?.phoneNumber,
            updatedAt: new Date(),
            patientBenefits: {
              isSubscriberPatient:
                orderToUpdate.patient?.patientBenefits.isSubscriberPatient,
              memberID: orderToUpdate.patient?.patientBenefits.memberID,
            },
          },
          paymentPlanDuration: orderToUpdate?.paymentPlanDuration,
          paymentPlanMinDownPaymentPercent:
            orderToUpdate?.paymentPlanMinDownPaymentPercent,
          paymentPlanEnabled: orderToUpdate?.paymentPlanEnabled,
          paymentPlanMinDownPaymentFlat:
            orderToUpdate?.paymentPlanMinDownPaymentFlat,
          benefitDetails: orderToUpdate?.benefitDetails,
          outreachStopped: orderToUpdate.outreachStopped,
          patientBenefits: orderToUpdate.patientBenefits,
        },
      }),
    }),
    approveDraftOrder: builder.mutation<
      ISingleOrderResponse,
      IOrderCreateRequest
    >({
      query: (orderToUpdate) => ({
        method: "PATCH",
        url: `/orders/approve/${orderToUpdate.orderId}`,
        body: orderToUpdate,
      }),
    }),
  }),
});

const publicOrderQueries = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    createPayment: builder.mutation<any, ICreatePaymentRequest>({
      query: (payment) => ({
        body: {
          amountInCents: payment.amountInCents,
          paymentType: capitalizeFirstLetter(payment.paymentType),
          stripeBalanceTransactionId: payment.stripeBalanceTransactionId,
          userId: payment.userId,
          providerId: payment.providerId,
          paymentPlan: payment.paymentPlan,
          paymentMethodId: payment.paymentMethodId,
          note: payment.note,
          payRemainingPrice: payment.payRemainingPrice,
        },
        method: "POST",
        url: `orders/${payment.orderId}/payment`,
      }),
    }),
    createOrderPaymentIntent: builder.mutation({
      query: ({
        orderId,
        paymentPlan,
        payRemainingPrice,
      }: {
        orderId: string;
        paymentPlan: boolean;
        payRemainingPrice: boolean;
      }) => ({
        url: `orders/${orderId}/create_payment_intent`,
        method: "POST",
        body: {
          paymentPlan,
          payRemainingPrice,
        },
      }),
    }),
    createOrderSetupIntent: builder.mutation({
      query: ({ orderId }) => ({
        url: `orders/${orderId}/create_setup_intent`,
        method: "POST",
        body: {},
      }),
    }),
    getOrderOverview: builder.query<
      ISingleOrderResumeResponse,
      string | undefined
    >({
      query: (orderId) => `/orders/${orderId}/overview`,
    }),
    getPublicOrder: builder.query<ISingleOrderResponse, string | undefined>({
      query: (orderId) => `/orders/${orderId}/public`,
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useCreateOrdersMutation,
  useCancelSingleOrderMutation,
  useUpdateOrderMutation,
  useApproveDraftOrderMutation,
  useSaveDraftMutation,
  useRedoOrderMutation,
} = orderQueries;

export const {
  useCreatePaymentMutation,
  useCreateOrderPaymentIntentMutation,
  useCreateOrderSetupIntentMutation,
  useGetOrderOverviewQuery,
  useGetPublicOrderQuery,
} = publicOrderQueries;
